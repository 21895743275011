var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "BatchProjectApplicationLibranyTenantForm-wrapper" },
    [
      _c(
        "form-panel",
        {
          attrs: {
            submitUrl: _vm.submitUrl,
            form: _vm.form,
            submitBefore: _vm.submitBefore,
          },
        },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "批量配置小程序应用" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "第三方平台产品" } },
                    [
                      _c("chosen-list-panel", {
                        attrs: {
                          list: _vm.form.microappList,
                          textAs: "appName",
                        },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "microappList", $event)
                          },
                          select: _vm.communitySelect,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "授权应用",
                        rules: [
                          {
                            required: true,
                            message: "请选择授权应用",
                            trigger: "change",
                          },
                        ],
                        prop: "subCommunityIds",
                      },
                    },
                    [
                      _c("chosen-list-panel", {
                        attrs: {
                          list: _vm.form.subCommunityIds,
                          textAs: "subName",
                        },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "subCommunityIds", $event)
                          },
                          select: _vm.select,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "第三方平台产品列表",
          isShow: _vm.showCommunityMultiSelect,
          searchUrl: _vm.queryCommunityListURL,
          searchParams: _vm.communitySearchParams,
          headers: _vm.communityHeaders,
          responseParams: _vm.communityResponseParams,
          responseKey: _vm.communityResponseKey,
          backFill: _vm.form.microappList,
          handleData: _vm.handleData,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.showCommunityMultiSelect = $event
          },
          "update:is-show": function ($event) {
            _vm.showCommunityMultiSelect = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "microappList", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "microappList", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "产品类型" },
                  model: {
                    value: _vm.communitySearchParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.communitySearchParams, "name", $$v)
                    },
                    expression: "communitySearchParams.name",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "第三方平台名称", options: _vm.appTypeOps },
                  model: {
                    value: _vm.communitySearchParams.appType,
                    callback: function ($$v) {
                      _vm.$set(_vm.communitySearchParams, "appType", $$v)
                    },
                    expression: "communitySearchParams.appType",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("multi-select", {
        attrs: {
          title: "应用列表",
          isShow: _vm.showMultiSelect,
          searchUrl: _vm.getListURL,
          headers: _vm.headers,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.form.subCommunityIds,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.showMultiSelect = $event
          },
          "update:is-show": function ($event) {
            _vm.showMultiSelect = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "subCommunityIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "subCommunityIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { placeholder: "输入项目名称", label: "所属项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "应用名称" },
                  model: {
                    value: _vm.searchParams.subName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subName", $$v)
                    },
                    expression: "searchParams.subName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "应用类别", options: _vm.subHandleOps },
                  model: {
                    value: _vm.searchParams.subHandle,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subHandle", $$v)
                    },
                    expression: "searchParams.subHandle",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "应用类型", options: _vm.subTypeOps },
                  model: {
                    value: _vm.searchParams.subType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subType", $$v)
                    },
                    expression: "searchParams.subType",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }