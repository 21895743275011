<template>
  <div class="BatchProjectApplicationLibranyTenantForm-wrapper">
    <form-panel
      :submitUrl="submitUrl"
      :form="form"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block title="批量配置小程序应用">
          <el-form-item
            label="第三方平台产品"
          >
            <chosen-list-panel
              :list.sync="form.microappList"
              textAs="appName"
              @select="communitySelect"
            />
          </el-form-item>
          <el-form-item
            label="授权应用"
            :rules="[
              { required: true, message: '请选择授权应用', trigger: 'change' },
            ]"
            prop="subCommunityIds"
          >
            <chosen-list-panel
              :list.sync="form.subCommunityIds"
              textAs="subName"
              @select="select"
            />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select
      title="第三方平台产品列表"
      :isShow.sync="showCommunityMultiSelect"
      :searchUrl="queryCommunityListURL"
      :searchParams="communitySearchParams"
      :headers="communityHeaders"
      :responseParams="communityResponseParams"
      :responseKey="communityResponseKey"
      :backFill.sync="form.microappList"
      :handleData="handleData"
    >
      <template #searchSlot>
        <v-input label="产品类型" v-model="communitySearchParams.name" />
        <v-select
          label="第三方平台名称"
          v-model="communitySearchParams.appType"
          :options="appTypeOps"
        />
      </template>
    </multi-select>
    <multi-select
      title="应用列表"
      :isShow.sync="showMultiSelect"
      :searchUrl="getListURL"
      :headers="headers"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="form.subCommunityIds"
    >
      <template #searchSlot>
        <v-select2
          placeholder="输入项目名称"
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
        ></v-select2>
        <v-input label="应用名称" v-model="searchParams.subName" />
        <v-select
          label="应用类别"
          v-model="searchParams.subHandle"
          :options="subHandleOps"
        />
        <v-select
          label="应用类型"
          v-model="searchParams.subType"
          :options="subTypeOps"
        />
      </template>
    </multi-select>
  </div>
</template>

<script>
import { getListURL, queryCommunityListURL, relateURL } from "./api";
import { createImgVNode } from "common/vdom";
import {
  subHandleMap,
  setSubHandleOps,
  subTypeMap,
  setSubTypeOps,
  statusMap,
} from "views/application/applicationLibrary/map";
import { setAppTypeOps, appTypeMap } from "./map";
import {
  Col2Detail,
  Col2Block,
  ChosenListPanel,
  MultiSelect,
} from "components/bussiness";
import { communityParams } from "common/select2Params";

export default {
  name: "BatchProjectApplicationLibranyTenantForm",
  components: {
    Col2Detail,
    Col2Block,
    ChosenListPanel,
    MultiSelect,
  },
  data() {
    return {
      submitUrl: relateURL,
      subHandleOps: setSubHandleOps(1),
      subTypeOps: setSubTypeOps(1),
      appTypeOps: setAppTypeOps(1),
      communityParams,
      showMultiSelect: false,
      showCommunityMultiSelect: false,
      form: {
        microappList: [],
        subCommunityIds: [],
      },
      getListURL,
      headers: [
        {
          prop: "subImg",
          label: "应用图标",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "subName",
          label: "应用名称",
        },
        {
          prop: "communityName",
          label: "所属项目",
        },
        {
          prop: "subHandleText",
          label: "应用类别",
          formatter: (row) => subHandleMap[row.subHandle],
        },
        {
          prop: "subTypeText",
          label: "应用类型",
          formatter: (row) => subTypeMap[row.subType],
        },
        {
          prop: "subStatusText",
          label: "应用状态",
          formatter: (row) => statusMap[row.subStatus],
        },
      ],
      searchParams: {
        communityId: undefined,
        subName: "",
        subHandle: undefined,
        subType: undefined,
      },
      responseParams: {
        id: "subCommunityId",
        name: "subName",
      },
      responseKey: {
        id: "subCommunityId",
        name: "subName",
      },
      queryCommunityListURL,
      communitySearchParams: {
        name: "",
        appType: undefined,
      },
      communityHeaders: [
        {
          prop: "name",
          label: "产品类型",
        },
        {
          prop: "appType",
          label: "第三方平台名称",
          formatter: (row) => appTypeMap[row.appType],
        },
      ],
      communityResponseParams: {
        id: "appId",
        name: "appName",
      },
      communityResponseKey: {
        id: "appId",
        name: "appName",
      },
    };
  },
  computed: {},
  created() {
    this.$setBreadcrumb("批量配置");
  },
  methods: {
    communitySelect() {
      this.showCommunityMultiSelect = true;
    },
    select() {
      this.showMultiSelect = true;
    },
    submitBefore(data) {
      let microappLists = data.microappList.map(({ appName, obj }) => ({
        appId: obj.appId,
        appName,
        appType: obj.appType,
      }));
      // 操作日志添加操作对象参数 规则：小程序名称-应用名称-所属项目
      let dataObject1 = `(${microappLists
        .map((item) => item.appName)
        .join(",")}):`;
      data.subCommunityIds.forEach((item) => {
        dataObject1 += `${item.subName}-${item.obj.communityName};`;
      });
      data.dataObject = dataObject1.substring(0, dataObject1.length - 1);
      let subCommunityIdsList = [];
      data.subCommunityIds.map((item) => {
        subCommunityIdsList.push(item.subCommunityId);
      });
      data.subCommunityIds = subCommunityIdsList;
      data.microappList = microappLists;
      return true;
    },
    handleData(data) {
      data.appName = appTypeMap[data.appType] + "-" + data.name;
      return data;
    },
  },
};
</script>
